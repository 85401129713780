@layer modules, ui, base;
@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.RichText_main__L_7T3 a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.RichText_main__L_7T3 a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.RichText_main__L_7T3 a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.RichText_main__L_7T3 table {
  width: 100%;
  table-layout: fixed;
}

.RichText_main__L_7T3 table,
.RichText_main__L_7T3 td,
.RichText_main__L_7T3 tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.RichText_main__L_7T3 td {
  padding: var(--sk-space-8);
}

/* HTML sized tags */
.RichText_richTextIframe__sid0P,
.RichText_richTextImg__kXSvA {
  max-width: 100%;
}

}
@layer base {
  .Card_main__XlBDr {
  padding: var(--spacer-300);
  border-radius: var(--radius-200);
}

[data-theme="purple"].Card_main__XlBDr {
  background-color: var(--color-purple--500);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -510px -170px;
}

[data-theme="light"].Card_main__XlBDr {
  color: var(--color-black);
}

[data-theme="dark"].Card_main__XlBDr {
  border: thin solid var(--color-white);
  background-color: var(--color-black);
  color: var(--color-white);
}

[data-theme="dark"].Card_main__XlBDr,
[data-theme="purple"].Card_main__XlBDr {
  color: var(--color-white);
}

[data-theme="dark"].Card_main__XlBDr,
[data-theme="light"].Card_main__XlBDr {
  text-align: center;
}

[data-theme="dark"] .Card_button__xB7FW,
[data-theme="purple"] .Card_button__xB7FW {
  border-color: var(--color-white);
  background-color: var(--color-white);
  color: var(--color-black);
}

[data-theme="light"] .Card_button__xB7FW {
  border-color: var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);
}

.Card_main__XlBDr a {
  color: inherit;
}

.Card_title__cSkjz {
  /* add this weight in UI-Kit */
  font-weight: var(--black);
}

.Card_title__cSkjz + .Card_text__Hh5Kw {
  margin-top: var(--spacer-100);
}

.Card_text__Hh5Kw p {
  margin: 0;
}

@media (max-width: 47.999rem) {
  .Card_main__XlBDr {
    margin: 0;
  }

  .Card_button__xB7FW {
    margin-top: var(--spacer-300);
  }
}

@media (min-width: 48rem) {
  /* For the header desktop, only the button is display */
  .Card_main--menu__1_OYn :not(.Card_buttonWrapper__Hg1N4, .Card_button__xB7FW) {
    display: none;
  }

  [data-theme="purple"].Card_main__XlBDr:not(.Card_main--menu__1_OYn) {
    --horizontalyPadding: 44px;
    display: grid;
    grid-template: auto auto / 1fr auto;
    padding: var(--horizontalyPadding) 72px;
    -moz-column-gap: var(--horizontalyPadding);
         column-gap: var(--horizontalyPadding);
    background-position: -200px -200px;
  }

  [data-theme="light"].Card_main__XlBDr {
    border: thin solid var(--color-grey--050);
  }

  [data-theme="dark"].Card_main__XlBDr,
  [data-theme="light"].Card_main__XlBDr {
    padding: var(--spacer-400);
  }

  [data-theme="dark"] .Card_button__xB7FW,
  [data-theme="light"] .Card_button__xB7FW {
    margin-top: var(--spacer-300);
  }

  .Card_main--menu__1_OYn {
    padding: 0;
    /* For the header desktop, we avoid the preload of the image */
    background-image: none !important;
  }

  .Card_title__cSkjz {
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .Card_title__cSkjz + .Card_text__Hh5Kw {
    margin-top: var(--spacer-200);
  }

  .Card_text__Hh5Kw {
    font-size: var(--editoBody);
    line-height: var(--editoBodyLineHeight);
  }

  .Card_title__cSkjz,
  .Card_text__Hh5Kw {
    grid-column: 1;
  }

  .Card_buttonWrapper__Hg1N4 {
    display: flex;
    grid-column: 2;
    grid-row: 1 / span 2;
    align-items: center;
    justify-content: center;
  }
}

}
@layer base {
  .Navigation_list__z4xSw {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Navigation_link__OZnC7 {
  display: block;
  position: relative;
  padding: var(--spacer-200) 0;
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
}

.Navigation_link--isButton__1X8Lz,
.Navigation_link--isButton__1X8Lz:hover {
  display: inline-flex;
  border: none;
  background-color: transparent;
  color: var(--color-black);
  font-family: inherit;
}

.Navigation_link--isButton__1X8Lz::before {
  content: none;
}

.Navigation_link--active__OYSWn {
  color: var(--mainColor);
  font-weight: var(--bold);
}

.Navigation_link--active__OYSWn::after,
.Navigation_list--hasChildrenActive__d8xjo .Navigation_link__OZnC7 {
  border-color: var(--mainColor);
}

.Navigation_list--isDropdownOpened__ML4XS .Navigation_link--isButton__1X8Lz svg {
  transform: rotate(-180deg) translateX(-8px);
  transform-origin: center;
}

.Navigation_list--isDropdownOpened__ML4XS .Navigation_dropdown__F9piI {
  visibility: visible;
  pointer-events: initial;
}

.Navigation_dropdown__F9piI {
  display: grid;
  visibility: hidden;
  grid-template-columns: 1fr;
  gap: var(--spacer-300);
  margin: 0;
  padding: 0;
  transition: 280ms all ease-out;
  list-style: none;
}

.Navigation_dropdownLink__gvJsW {
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
}

@media (max-width: 63.999rem) {
  .Navigation_list__z4xSw {
    flex-direction: column;
  }

  .Navigation_link__OZnC7 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: var(--subheadSmall);
    line-height: var(--subheadSmallLineHeight);
    text-align: left;
  }

  .Navigation_listItem--active__BWzp7,
  .Navigation_link--active__OYSWn {
    display: none;
  }

  .Navigation_link--isButton__1X8Lz {
    justify-content: space-between;
  }

  .Navigation_list--secondary__RZHAh {
    flex-direction: column;
    margin-bottom: var(--spacer-400);
  }

  .Navigation_listItem__uvNxw {
    border-bottom: thin solid var(--color-grey--100);
  }

  .Navigation_list--secondary__RZHAh .Navigation_link__OZnC7 {
    font-weight: var(--black);
  }

  .Navigation_list--secondary__RZHAh .Navigation_link--active__OYSWn {
    color: var(--color-black);
  }

  .Navigation_list--secondary__RZHAh .Navigation_link--active__OYSWn::after {
    content: none;
  }

  .Navigation_dropdown__F9piI {
    height: 0;
    overflow: hidden;
  }

  .Navigation_dropdownLink__gvJsW {
    display: flex;
    align-items: center;
  }

  .Navigation_dropdownLinkIcon__K2eb8 {
    padding-left: var(--spacer-100);
  }

  .Navigation_dropdownLinkSubtitle__3c6q3 {
    display: none;
  }

  .Navigation_list--isDropdownOpened__ML4XS .Navigation_dropdown__F9piI {
    height: initial;
    padding: var(--spacer-100) 0 var(--spacer-400);
  }
}

@media (min-width: 64rem) {
  .Navigation_list__z4xSw {
    gap: var(--spacer-100) var(--spacer-300);
  }

  .Navigation_link__OZnC7 {
    font-size: var(--smallBody);
    line-height: var(--smallBodyLineHeight);
    cursor: pointer;
  }

  .Navigation_link__OZnC7::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid currentcolor;
  }

  .Navigation_list__z4xSw:not(.Navigation_list--secondary__RZHAh) .Navigation_link__OZnC7 {
    padding: var(--spacer-100) 0;
  }

  .Navigation_link__OZnC7:not(.Navigation_link--active__OYSWn) {
    color: var(--color-white);
  }

  .Navigation_link__OZnC7:not(.Navigation_link--active__OYSWn)::after {
    content: none;
  }

  .Navigation_list--hasChildrenActive__d8xjo .Navigation_link__OZnC7 {
    color: var(--mainColor);
  }

  .Navigation_list--hasChildrenActive__d8xjo .Navigation_link__OZnC7::after {
    content: "";
  }

  .Navigation_list--isDropdownOpened__ML4XS [aria-expanded="true"].Navigation_link__OZnC7,
  .Navigation_link__OZnC7:not(.Navigation_link--active__OYSWn):hover,
  .Navigation_link__OZnC7:not(.Navigation_link--active__OYSWn)[aria-current] {
    color: var(--mainColor);
  }

  .Navigation_list--isDropdownOpened__ML4XS [aria-expanded="true"].Navigation_link__OZnC7::after,
  .Navigation_link__OZnC7:hover::after,
  .Navigation_link__OZnC7:not(.Navigation_link--active__OYSWn)[aria-current]::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--mainColor);
  }

  .Navigation_dropdown__F9piI {
    position: absolute;
    left: 506px;
    gap: var(--spacer-200) calc(var(--spacer-400) * 2);
    width: 100%;
    max-width: 440px;
    padding: var(--spacer-400);
    transform: rotateX(-90deg) translateX(-50%);
    transform-origin: top center;
    opacity: 0.3;
    background-color: var(--color-white);
    box-shadow: var(--default-shadow);
    color: var(--color-black);
  }

  .Navigation_dropdownLink__gvJsW {
    position: relative;
    font-size: var(--smallBody);
    line-height: var(--smallBodyLineHeight);
  }

  .Navigation_dropdownLink__gvJsW:hover::after {
    content: "";
    display: block;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: -15%;
    left: -2%;
    width: 104%;
    height: 130%;
    border-radius: var(--radius-200);
    background-color: var(--color-grey--050);
  }

  .Navigation_dropdownLinkIcon__K2eb8 {
    display: none;
  }

  .Navigation_dropdownLinkTitle__8WidS {
    font-weight: var(--bold);
  }

  .Navigation_dropdownLinkSubtitle__3c6q3 {
    color: var(--color-grey--500);
  }

  .Navigation_list--isDropdownOpened__ML4XS .Navigation_dropdown__F9piI {
    transform: rotateX(0) translateX(-50%);
    opacity: 1;
  }

  .Navigation_linkIcon__f18rz {
    display: none;
  }
}

}
@layer base {
  .Locales_button__O_4cW > button {
  padding-left: var(--spacer-200);
  background-color: transparent;
  color: var(--color-white);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
  text-transform: uppercase;
}

.Locales_button--menu__0umcN > button {
  padding-left: 0;
  border-color: transparent;
  color: var(--color-black);
}

.Locales_button__O_4cW > button:focus {
  border-color: transparent;
}

.Locales_button__O_4cW ul button {
  padding: 0;
}

.Locales_button__O_4cW a {
  display: block;
  width: 100%;
  padding: var(--spacer-150) var(--spacer-200);
  text-decoration: none;
}

.Locales_button--menu__0umcN a {
  color: var(--color-black);
}

@media (min-width: 48rem) {
  .Locales_button__O_4cW > button {
    padding: 0;
    color: var(--color-white);
  }

  .Locales_button__O_4cW > button:hover::before {
    content: none;
  }

  .Locales_button--menu__0umcN ul {
    /* To avoid positioning outside the width device */
    inset: inherit !important;
    right: 0 !important;
  }
}

}
@layer base {
  .Icon_hdr-Icon__KJAoi {
  --iconSize: 20px;
  width: var(--iconSize);
  min-width: var(--iconSize);
  height: var(--iconSize);
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_hdr-Icon--isBig__nDZPe {
  --iconSize: 24px;
}

}
@layer base {
  .LoginLink_main__H63Ym {
  display: flex;
  align-items: center;
  background-color: initial; /* Spark override */
  font-weight: var(--normal); /* Spark override */
}

.LoginLink_icon__y9QnN {
  padding-right: 0.5rem;
}

}
@layer base {
  .Header_main__ffXMQ {
  --header-padding: 20px;
  --header-maxWidth: var(--large-breakpoint);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 2;
  box-sizing: border-box;
  flex: 0;
  max-width: var(--header-maxWidth);
}

.Header_brand__05oJi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 var(--header-padding);
  background-color: var(--color-black);
}

.Header_brand__05oJi a {
  display: flex;
  align-items: center;
}

.Header_mainNav__mnSGN {
  flex: 1;
}

.Header_menuButton___fBiT {
  border: none;
  background-color: transparent;
  color: var(--color-white);
}

.Header_wrapper__JwXdC {
  --height: -56px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
  top: var(--height);
  transition: all 0.3s ease-in-out;
}

.Header_wrapper--fixed__eXk0Y {
  position: fixed;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 2;
  top: 0;
  width: 100%;
  background: var(--color-black);
}

.Header_wrapper--fixedRemove__dJ3ae {
  top: var(--height);
}

.Header_wrapper--fixed__eXk0Y .Header_topNav__Z0XQK {
  display: none;
}

@media (max-width: 63.999rem) {
  .Header_topNav__Z0XQK,
  .Header_mainNav__mnSGN,
  .Header_language__mYjFq,
  .Header_login__7vg7N,
  .Header_contact__MRjkN {
    display: none;
    margin: 0 var(--spacer-200);
  }

  .Header_main--open__X92ec {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* stylelint-disable-next-line */
    height: 100dvh;
    overflow: auto;
  }

  .Header_main--open__X92ec .Header_topNav__Z0XQK,
  .Header_main--open__X92ec .Header_mainNav__mnSGN,
  .Header_main--open__X92ec .Header_language__mYjFq,
  .Header_main--open__X92ec .Header_login__7vg7N,
  .Header_main--open__X92ec .Header_contact__MRjkN {
    display: initial;
  }

  .Header_main--open__X92ec .Header_brand__05oJi {
    position: sticky;
    z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 0;
  }

  .Header_main--open__X92ec .Header_language__mYjFq {
    margin-bottom: var(--spacer-400);
  }

  .Header_main--open__X92ec .Header_login__7vg7N {
    margin-bottom: var(--spacer-500);
    padding: 0;
  }

  .Header_main--open__X92ec .Header_contact__MRjkN {
    margin-bottom: var(--spacer-200);
  }

  .Header_topNav__Z0XQK {
    margin-top: var(--spacer-200);
    margin-bottom: var(--spacer-800);
  }
}

@media (min-width: 64rem) {
  .Header_wrapper__JwXdC:not(.Header_wrapper--fixed__eXk0Y) {
    background: var(--color-black);
    background: linear-gradient(0deg, var(--color-black) 58.33%, var(--color-grey--700) 58.33%);
  }

  .Header_main__ffXMQ {
    --header-maxWidth: var(--large-breakpoint);
    display: grid;
    position: relative;
    grid-template: auto / auto 1fr auto auto;
    grid-template-areas:
      "topNav topNav topNav topNav topNav"
      "logo mainNav language login contact";
    gap: 0 0;
    width: 100%;
    margin: auto;
    padding: 0 var(--spacer-200);
    color: var(--color-white);
  }

  .Header_siteLogo__BwDPP {
    width: 181px;
    height: 32px;
  }

  .Header_mainNav__mnSGN {
    grid-area: mainNav;
    min-height: 56px;
  }

  .Header_topNav__Z0XQK {
    position: relative;
    grid-area: topNav;
    min-height: 40px;
  }

  .Header_language__mYjFq {
    display: flex;
    grid-area: language;
    justify-content: flex-end;
    padding-right: var(--spacer-300);
  }

  .Header_login__7vg7N {
    grid-area: login;
    margin-right: var(--sk-space-32);
    margin-left: var(--sk-space-8);
  }

  .Header_contact__MRjkN {
    display: flex;
    grid-area: contact;
    align-items: center;
    justify-content: flex-end;
  }

  .Header_brand__05oJi {
    position: relative;
    grid-area: logo;
    min-height: inherit;
    padding: 0 56px 0 0;
  }

  .Header_menuButton___fBiT {
    display: none;
  }
}

@media (min-width: 80rem) {
  .Header_main__ffXMQ {
    --header-maxWidth: var(--major-breakpoint);
  }
}

}
@layer base {
  .Brand_app-Brand__c4Qbx {
  display: flex;
  align-items: center;
  margin: 0 var(--sk-space-16) 0 var(--sk-space-24);
}

.Brand_app-Brand--footer__W59hv {
  margin-bottom: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .Brand_app-Brand--footer__W59hv {
    margin-bottom: 0;
  }
}

@media (min-width: 64rem) {
  .Brand_app-Brand__c4Qbx {
    margin: 0;
  }
}

/******************************
  JT LOGO
*******************************/

.Brand_app-JtLogo__lRH5S {
  width: auto;
  height: 1.5rem;
}

@media (min-width: 48rem) {
  .Brand_app-JtLogo___footer__7MXeC {
    height: 2rem;
  }
}

@media (min-width: 64rem) {
  .Brand_app-JtLogo___header__EE3FC {
    height: 2rem;
  }
}

/******************************
  CC LOGO
*******************************/

.Brand_app-Brand__CcImage__DidkI {
  width: var(--image-width);
  border-radius: var(--image-border-radius);
}

.Brand_app-Brand__CcImage__DidkI:last-of-type {
  margin-right: var(--image-spacing);
}

.Brand_app-Brand__CcImage__DidkI + .Brand_app-Brand__CcImage__DidkI {
  margin-left: var(--image-spacing);
}

.Brand_app-Brand__CcTitleGroup___rWb3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--titleGroup-gap);
  margin-right: var(--titleGroup-margin-right);
}

.Brand_app-Brand__CcTitle__zWSQT {
  color: currentcolor;
  font-family: var(--fontHeading);
  font-size: var(--title-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.7px;
  line-height: var(--title-size);

  /* the name of the Career Center is displayed on a specific maximum number of lines and truncated with ellipsis beyond that */
  /* stylelint-disable-next-line order/properties-order */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--title-line-clamp);
  line-clamp: var(--title-line-clamp);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Brand_app-Brand__CcSubtitle__a3OOB {
  width: auto;
  height: var(--subtitle-height);
}

.Brand_app-Brand__CcLogo__SvY8S {
  /* Style specific to this element */
  display: flex;
  align-items: center;

  /* 
    We define custom properties for the two CC logo formats: small and big.
    They will be used by children of this element to adapt their styles.
  */

  --small-image-width: 24px;
  --big-image-width: 48px;

  --small-image-border-radius: var(--sk-radius-2);
  --big-image-border-radius: var(--sk-radius-4);

  --small-image-spacing: var(--sk-space-4);
  --big-image-spacing: var(--sk-space-8);

  --small-titleGroup-gap: var(--sk-space-2);
  --big-titleGroup-gap: var(--sk-space-4);

  --small-titleGroup-margin-right: 0;
  --big-titleGroup-margin-right: var(--sk-space-8);

  --small-title-size: 14px;
  --big-title-size: 16px;

  --small-title-line-clamp: 1;
  --big-title-line-clamp: 2;

  --small-subtitle-height: 9px;
  --big-subtitle-height: 10px;

  /* Mobile style for header and footer: small */

  --image-width: var(--small-image-width);
  --image-border-radius: var(--small-image-border-radius);
  --image-spacing: var(--small-image-spacing);

  --titleGroup-gap: var(--small-titleGroup-gap);
  --titleGroup-margin-right: var(--small-titleGroup-margin-right);

  --title-size: var(--small-title-size);
  --title-line-clamp: var(--small-title-line-clamp);

  --subtitle-height: var(--small-subtitle-height);
}

/* Tablet style for footer: big */

@media (min-width: 48rem) {
  .Brand_app-Brand__CcLogo___footer__HJoI6 {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

/* Desktop style for header and footer: big */

@media (min-width: 64rem) {
  .Brand_app-Brand__CcLogo__SvY8S {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

}
@layer base {
  .FooterLegals_main__QJw89 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32) / 2);
  font-size: 0.8125rem;
}

.FooterLegals_links__ncIjV {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FooterLegals_link__klHBy {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

.FooterLegals_link__small__OVhlQ {
  font-size: 13px;
  line-height: var(--typo-body-3-line-height);
}

.FooterLegals_copyright__zAj6j {
  margin-top: calc(var(--sk-space-32) / 2);
  color: var(--themeHeaderColor, var(--sk-color-white));
  line-height: 1.125rem;
  text-align: center;
}

@media (min-width: 48rem) {
  /* --medium-viewport */
  .FooterLegals_main__QJw89 {
    padding: calc(var(--sk-space-32) / 2) 0;
  }

  .FooterLegals_links__ncIjV {
    flex-direction: row;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-wrap: wrap;
  }

  .FooterLegals_item__mPfMv {
    line-height: inherit;
  }

  .FooterLegals_copyright__zAj6j {
    text-align: right;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .FooterLegals_main__QJw89 {
    flex-direction: row;
    padding: var(--sk-space-48) 0;
  }

  .FooterLegals_item__mPfMv {
    padding-right: 2rem;
  }

  .FooterLegals_link__klHBy {
    padding: 0;
  }

  .FooterLegals_copyright__zAj6j {
    margin-top: inherit;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .FooterLegals_link__klHBy,
[data-theme="jobteaser-uishift"] .FooterLegals_copyright__zAj6j {
  color: var(--sk-color-white);
}

}
@layer base {
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
  font-size: 1rem;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
  display: flex;
  position: relative;
  outline: none;
}

@media (max-width: 767px) {
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
    justify-content: center;
  }
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within {
  pointer-events: none;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  font-family: var(--fontFamily);
  white-space: nowrap;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
.LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  position: absolute;
  top: 100%;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: 8px;
  clip: auto;
  background: var(--sk-color-white);
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph:hover {
  background-color: var(--sk-color-grey-025);
}

.LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  display: flex;
  align-items: center;
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 {
  padding: 0.8125rem 0.75rem;
  border-radius: var(--sk-radius-4);
  background-color: var(--sk-color-white);
  color: var(--sk-color-grey-500);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  padding-right: 0.5rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkText__5T4xT {
  margin-left: 12px;
  color: var(--sk-color-grey-600);
  font-size: 0.75rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.25rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
  display: flex;
  align-items: center;
  margin: 0;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX:focus-within,
.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  background-color: var(--sk-color-grey-025);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh {
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    width: 100%;
    padding: 1.125rem 1.875rem;
    border: thin solid var(--sk-color-grey-200);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU,
  .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    flex: 1;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: -300px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 0;
    overflow-y: auto;
    border: thin solid var(--sk-color-grey-100);
    box-shadow: var(--shadow-default);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: inherit;
    bottom: calc(100% + 1px);
    height: auto;
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border-bottom: thin solid var(--sk-color-grey-100);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 16px 20px;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    box-sizing: border-box;
    width: 345px;
    max-height: 50vh;
    overflow-y: visible;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    display: none;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    width: inherit;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    display: flex;
    top: inherit;
    right: 0;
    bottom: calc(100% + 1px);
    flex-wrap: wrap;
    width: auto;
    padding: 8px;
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    box-sizing: border-box;
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
    padding: 12px 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    display: flex;
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
    cursor: pointer;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s {
  color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s::after {
  background-color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  border-radius: var(--sk-radius-8);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    border-radius: var(--sk-radius-8);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    border-radius: var(--sk-radius-8);
  }
}

}
@layer base {
  .Footer_main__ESpFn {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  margin-top: auto;
  background-color: var(--themeHeaderTextColor, var(--sk-color-black));
  color: var(--sk-color-white);
}

.Footer_main__ESpFn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Footer_brand__SLiAO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32) / 2) 0;
  border-bottom: 0.0625em solid #dbdbdb;
}

.Footer_brand__SLiAO svg path {
  fill: var(--sk-color-white);
}

.Footer_locales__L2OwV {
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 47.999rem) {
  /* --small-and-less-only */
  .Footer_logo__XW1BB {
    height: 1.5rem;
  }

  .Footer_logo__smallScreenBottomMargin__sGMQT {
    margin-bottom: var(--sk-space-16);
  }
}

@media (min-width: 48rem) {
  /* --medium-viewport */
  .Footer_container__z6X6Q {
    --layout-size: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--layout-size);
    margin: auto;
    padding: 0 var(--sk-space-32);
  }

  .Footer_brand__SLiAO {
    flex-direction: row;
  }

  .Footer_brandContainer__5rmxA {
    display: flex;
  }

  .Footer_locales__L2OwV {
    margin: 0 1rem 0 0;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Footer_container__z6X6Q {
    --layout-size: var(--mj-breakpoint);
    padding: 0 var(--sk-space-24);
  }

  .Footer_brand__SLiAO {
    padding: var(--sk-space-48) 0;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Footer_main__ESpFn {
  background-color: var(--sk-color-black);
}

/* the footer is NOT displayed on the printed version of the page */

@media print {
  .Footer_main__ESpFn {
    display: none;
  }
}

}
@layer base {
  .Layout_container__vfesy {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 0;
}

.Layout_mainContent__Ny0av {
  display: grid;
  flex: 1;
}

@media (max-width: 47.999rem) {
  .Layout_container--menuOpen__N1_KN {
    max-height: 100vh;
    max-height: 100dvh;
    overflow: hidden;
  }
}

}
